import './medium.css';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box } from 'rebass';
import useSWR from 'swr';

import { useEntity } from 'entities/hooks';
import { useScrollToItem } from 'contexts/scroll';
import { StoryMediumWrap, Wrap } from './styled';
import { getHeightAndWidthForMedium } from './helper';
import { selectMedium } from './selectors';
import AudioMedium from './medium-types/audio/index';
import EditButtons from './edit-buttons';
import ImageMedium from './medium-types/image';
import MoveOverlay from './move-overlay';
import Prompt from './prompt';
import TextMedium from './medium-types/text';
import VideoMedium from './medium-types/video';
import ExternalMedium from './medium-types/external';
import TextCaption from './text-caption';
import ShowTranscription from 'stories/story-page-activity/show-transcription';
import { getSWR } from 'utils/api';

const renderMedium = (id, mediumType, lastItem, editing, isCarousel, height) => {
  if (mediumType === 'external-media')
    return <ExternalMedium id={id} height={height} isCarousel={isCarousel} />;
  if (mediumType === 'texts' && lastItem && !editing) {
    return (
      <Wrap>
        <TextMedium id={id} />
      </Wrap>
    );
  }
  if (mediumType === 'texts') {
    return <TextMedium id={id} editing={editing} />;
  }
  if (mediumType === 'images') {
    return <ImageMedium id={id} isCarousel={isCarousel} />;
  }
  if (mediumType === 'videos') {
    return <VideoMedium id={id} isCarousel={isCarousel} height={height} />;
  }
  if (mediumType === 'audios') {
    return <AudioMedium id={id} editing={editing} />;
  }
  return false;
};

const StoryMedium = ({
  mediumType,
  medium,
  match,
  lastItem,
  promptDelete,
  promptMove,
  isGrouped,
  isCarousel,
  itemPosition,
  pagePosition,
  onClick,
  editing,
  captionTextId,
  captionAudioId,
}) => {
  const [refreshInterval, setRefreshInterval] = useState(0);

  useSWR(`/v2/media/${medium?.id}`, getSWR, { refreshInterval });

  const isTranscribed = medium?.transcribe === true

  useEffect(() => {
    if (
      isTranscribed && !medium?.transcription
    ) {
      setRefreshInterval(5000);
    }
    if (refreshInterval !== null && !!medium?.transcription) {
      setRefreshInterval(0);
    }
  }, [isTranscribed, medium?.transcription, setRefreshInterval]);

  const { entity: captionText, isPresent: hasTextCaption } = useEntity({
    type: 'texts',
    id: captionTextId || '',
  });
  const { isPresent: hasAudioCaption } = useEntity({
    type: 'audios',
    id: captionAudioId || '',
  });
  const [scrollRef] = useScrollToItem(medium.id);
  if (!Object.keys(medium).length) return false;

  const { height, width } = getHeightAndWidthForMedium(medium);

  const getMaxHeight = () => {
    if (promptDelete || promptMove) return '300px';
    if (isCarousel) return '400px';
    return height;
  };

  return (
    <Box ref={scrollRef} css="position: relative;">
      <StoryMediumWrap
        className={`story-medium-${medium.type.toLowerCase()}`}
        style={{
          height: promptDelete || promptMove ? '100%' : height,
          width,
          maxHeight: getMaxHeight(),
          maxWidth: width,
          minHeight: isCarousel ? '300px' : height,
          margin: 'auto',
        }}
        {...{ editing }}
        onClick={onClick}
      >
        {editing && (
          <EditButtons
            id={medium.id}
            pageId={medium['owner-id']}
            pagePosition={pagePosition}
            mediumType={mediumType}
            isGrouped={isGrouped}
            storyId={match.params.id}
          />
        )}
        {promptMove && <MoveOverlay {...{ itemPosition, isGrouped }} />}
        <Prompt
          id={medium.id}
          pageId={medium['owner-id']}
          mediumType={mediumType}
          storyId={match.params.id}
          promptDelete={promptDelete}
        >
          {renderMedium(medium.id, mediumType, lastItem, editing, isCarousel, height)}
        </Prompt>
      </StoryMediumWrap>
      {medium?.transcription && <ShowTranscription transcription={medium.transcription} />}
      {hasAudioCaption && (
        <Box bg="photoGradientEnd" css="position: absolute; bottom: 0; width: 100%;">
          <AudioMedium id={captionAudioId} />
        </Box>
      )}

      {hasTextCaption && <TextCaption mediumType={mediumType} text={captionText.text} />}
    </Box>
  );
};

StoryMedium.propTypes = {
  medium: PropTypes.shape({
    id: PropTypes.string,
    page: PropTypes.shape({ id: PropTypes.string }),
    type: PropTypes.string,
  }),
  mediumType: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  lastItem: PropTypes.bool,
  promptDelete: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isCarousel: PropTypes.bool,
  itemPosition: PropTypes.string,
  promptMove: PropTypes.bool,
  pagePosition: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  editing: PropTypes.bool,
  captionTextId: PropTypes.string,
  captionAudioId: PropTypes.string,
};

StoryMedium.defaultProps = {
  medium: {},
  lastItem: false,
  promptDelete: false,
  isGrouped: false,
  isCarousel: false,
  itemPosition: '',
  promptMove: false,
  onClick: () => { },
  editing: false,
  captionTextId: null,
  captionAudioId: null,
};

const mapStateToProps = (state, ownProps) => {
  const medium = selectMedium(state, ownProps.id, ownProps.mediumType);
  const promptDelete = state.storyMedia.destroyPrompts.includes(ownProps.id);
  return { medium, lastItem: ownProps.lastItem, promptDelete };
};

export default withRouter(connect(mapStateToProps)(StoryMedium));
