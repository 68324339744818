import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { TextFieldWrap, Label, ErrorMessageWrap } from './styled';

const TextField = ({ field, form, placeholder, label, showError, type = "input", borderRadius, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const { fontSize } = props;

  return (
    <TextFieldWrap error={showError && error && touched} {...props}>
      {label && <Label {...field}>{label}</Label>}
      <input
        {...field}
        placeholder={placeholder || ''}
        type={type}
        autoComplete="off"
        style={{ width: '100%', borderRadius }}
      />
      {showError && error && touched && (
        <ErrorMessageWrap {...field} fontSize={fontSize * 0.9}>
          {error}
        </ErrorMessageWrap>
      )}
    </TextFieldWrap>
  );
};

TextField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showError: PropTypes.bool,
};

TextField.defaultProps = {
  width: 320,
  borderRadius: 6,
  fontSize: 16,
  label: '',
  placeholder: '',
  showError: false,
};

export default TextField;
