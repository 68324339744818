import { Box, Flex } from 'rebass';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { string, object } from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import difference from 'lodash/difference';
import pick from 'lodash/pick';
import posed from 'react-pose';
import { trigger } from 'swr';

import { DatePicker } from '../../app/form/formik';
import ButtonBar from '../../story-pages/button-bar';
import { bodyWithLockVersion, getJSDateFromISO } from '../../app/form/helper';
import { destroyStory, updateStory } from '../actions';
import { useEntity } from '../../entities/hooks';
import { useMatch } from '../../app/hooks';
import { useHistory } from 'react-router-dom';

import Main from './main';
import MoveStoryPageProvider from '../edit-story/move';
import NewStoryHeader from './header';
import StoryPageList from '../../story-pages/list';
import { useStoryWithJournalId } from '../hooks/index';
import { CurrentCollectionContext } from '../../collections/current-collection-context';
import { useRouteFromEntityParams } from '../../collections/hooks/index';

const ListWrap = posed.div({
  loading: { opacity: 0.25 },
});

const NewStoryPages = props => {
  const {
    params: { id },
  } = useMatch();
  const { replace, push } = useHistory();
  const { entity } = useEntity({ id, type: 'stories' });
  const story = useStoryWithJournalId(entity);
  const { currentCollection, collectionsFilter } = useContext(CurrentCollectionContext);
  const backToCollectionRoute = useRouteFromEntityParams({
    type: currentCollection.type,
    id: currentCollection.id,
  });

  const pushBack = () => {
    if (currentCollection.id) {
      return push(backToCollectionRoute);
    }
    if (collectionsFilter) {
      return push(`/${collectionsFilter}`);
    }
    return push('/my-stories');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Formik
      initialValues={{
        'began-on': (story['began-on'] && getJSDateFromISO(story['began-on'])) || new Date(),
        'ended-on': story['ended-on'] ? getJSDateFromISO(story['ended-on']) : '',
        'journal-id': story['journal-id'],
      }}
      validationSchema={object().shape({
        'began-on': string(),
        'ended-on': string(),
      })}
      onSubmit={async values => {
        const isDirty = difference(
          Object.values(pick(story, ['began-on', 'ended-on'])),
          Object.values(pick(values, ['began-on', 'ended-on']))
        );
        if (isDirty) {
          await props.updateStory(id, bodyWithLockVersion(story, values));
        }
        replace(`/stories/new/${id}/cover`);
      }}
    >
      {({ isSubmitting, setSubmitting, submitForm, values }) => (
        <>
          <NewStoryHeader
            disabled={isSubmitting}
            onCancel={() => {
              setSubmitting(true);
              props.destroyStory(id, currentCollection.id).then(() => {
                trigger('/v3/my/chronicle_collection');
                pushBack();
              });
            }}
            onNext={submitForm}
            title="New Story"
          />
          <Main>
            <Flex justifyContent="center" alignItems="flex-end" mt={3} mb={4}>
              <Field
                component={DatePicker}
                name="began-on"
                showError
                width="100%"
                label="Story Date"
                placeholder="Start Date"
                maxDate={values['ended-on']}
              />
              <Box p={2} />
              <Field
                component={DatePicker}
                name="ended-on"
                showError
                width="100%"
                pickerProps={{
                  popperPlacement: 'bottom-end',
                }}
                placeholder="End Date"
                minDate={values['ended-on']}
              />
            </Flex>

            <MoveStoryPageProvider>
              <ListWrap pose={isSubmitting ? 'loading' : ''}>
                <ButtonBar storyId={id} position={0} />
                <StoryPageList storyId={id} editing />
              </ListWrap>
            </MoveStoryPageProvider>
          </Main>
        </>
      )}
    </Formik>
  );
};

NewStoryPages.propTypes = {
  destroyStory: PropTypes.func.isRequired,
  updateStory: PropTypes.func.isRequired,
};

export default connect(null, { destroyStory, updateStory })(NewStoryPages);
