import 'react-datepicker/dist/react-datepicker.css';

import { DateTime } from 'luxon';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import Select from 'react-select';

import { TextFieldWrap, Label, ErrorMessageWrap } from '../text/styled';

const days = Array.from({ length: 31 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

const months = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  label: new Date(0, i).toLocaleString('default', { month: 'short' }), // Full month name
}));

const years = Array.from({ length: 121 }, (_, i) => ({
  value: new Date().getFullYear() - i,
  label: (new Date().getFullYear() - i).toString(),
}));

export const customStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    padding: 20,
    backgroundColor: isSelected ? '#7a69bf' :
      isFocused ? '#bfb3ff' : 'white',
    width: '100%',
  }),
  control: (base, { isFocused }) => ({
    display: 'flex',
    border: isFocused ? '1px solid #7a69bf' : '1px solid #e6e6e6',
    borderRadius: '8px',
    background: 'white',
    boxShadow: 'none',
    height: '44px',
    width: '100%',

  }),
  valueContainer: base => ({
    ...base,
    padding: '12px 16px',
    width: '100%',
  }),
  singleValue: base => ({
    ...base,
    color: '#666',
    width: '100%',
  }),
};

const DateField = ({
  field,
  form,
  placeholder,
  label,
  showError,
  minDate,
  maxDate,
  pickerProps,
  ...props
}) => {
  const currentDate = (field.value && new Date(field.value)) || null

  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  useEffect(() => {
    if (currentDate) {
      setDay(currentDate.getDate())
      setMonth(currentDate.getMonth() + 1)
      setYear(currentDate.getFullYear())
    }

  }, [currentDate])

  useEffect(() => {
    if (day && month && year)
      updateDate()

  }, [day, month, year])

  const handleDayChange = (e) => {
    const selectedDay = Number(e.value);
    setDay(selectedDay);
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.value;
    setMonth(selectedMonth);
  };

  const handleYearChange = (e) => {
    const selectedYear = Number(e.value);
    setYear(selectedYear);
  };

  const updateDate = () => {
    const newDate = new Date(year, month - 1, day);
    form.setFieldValue(field.name, DateTime.fromJSDate(newDate).toISO())
  };

  return (
    <TextFieldWrap error={error && touched} {...props}>
      {label && <Label {...field}>{label}</Label>}
      <Flex mb={2} justifyContent="space-around">

        <Select
          className="react-select-container"
          options={days}
          value={day ? { label: day, value: day } : undefined}
          onChange={handleDayChange}
          styles={customStyles}
          placeholder="DD"
        />

        <Select
          className="react-select-container"
          options={months}
          value={month ? months[month - 1] : undefined}
          onChange={handleMonthChange}
          styles={customStyles}
          placeholder="MM"
        />

        <Select
          className="react-select-container"
          options={years}
          value={year ? { label: year, value: year } : undefined}
          onChange={handleYearChange}
          styles={customStyles}
          placeholder="YYYY"
        />
      </Flex>
      {error && touched && <ErrorMessageWrap {...field}>{error}</ErrorMessageWrap>}
    </TextFieldWrap>
  );
};

DateField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showError: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  pickerProps: PropTypes.shape({}),
};

DateField.defaultProps = {
  width: 320,
  borderRadius: 6,
  fontSize: 16,
  label: '',
  placeholder: '',
  showError: false,
  minDate: null,
  maxDate: null,
  pickerProps: {},
};

export default DateField;
