import React from 'react';
import { Text, Box, Flex } from 'rebass';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { createMediaForFiles } from 'story-media/actions';
import FileDropperField from './FileDropperField';
import Footer from './Footer';
import QuestionBar from './QuestionBar';
import UploadMedia from './UploadMedia';
import { useMobileCheckHook } from 'story-media/hooks';
import Wrapper from './Wrapper';

const Step2 = ({ attributes, storyId, onStepDown, onStepUp, percentComplete }) => {
    const dispatch = useDispatch();
    const isFromMobile = useMobileCheckHook()

    const submitMedia = (files) => {
        dispatch(createMediaForFiles(storyId, files, 0, true));
        onStepUp()
    }

    function onSubmit({ file }) {
        submitMedia(file)
    }

    return (
        <Wrapper justifyContent="center" alignItems="left">
            <QuestionBar question={attributes?.question} />

            <Text
                p={2}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                mt={5}
                ml={3}
                mb={3}
            >
                <i style={{ color: 'rgba(22, 22, 29, 0.70)' }}>(Optional)</i><br /> Want to add some photos and/or videos that help tell this story?
            </Text>
            {isFromMobile ?
                <Box m="auto" my={2}>
                    <UploadMedia question={attributes?.question} type="image/*,video/*" label="Upload a video or image" onSubmitMedia={submitMedia} />
                </Box>
                :
                <Formik initialValues={{}} onSubmit={onSubmit}>
                    <Box>
                        <FileDropperField
                            accept='image/*,video/*'
                            label="video or image"
                            multiple
                            name="file"
                            buttonText="Add visuals"
                        />
                    </Box>
                </Formik>}
            <Text
                color="#260050"
                fontSize="18px"
                letterSpacing="-0.396px"
                textAlign="center"
            >You can select multiple at once.</Text>

            <Footer onBack={onStepDown} onNextPress={onStepUp} percentComplete={percentComplete} nextButtonText="Next" />
        </Wrapper>
    );
};

export default Step2;
