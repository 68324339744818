import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Box, Text, Flex } from 'rebass';
import { updateMember } from 'members/actions';
import useSendToGTM from 'utils/googleTagManager';
import { ReactComponent as Rating } from './assets/rating.svg';
import Footer from 'stories/tell-a-story/Footer';
import styled from '@emotion/styled';
import useMatch from 'app/hooks/match';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from 'app/form/formik/text/index';
import useEntity from 'entities/hooks/use-entity';
import FullPageFormWrap from 'app/full-page-form/wrap';
import { useCreateNewV3Account, useLogin } from 'auth/hooks';
import { useGetMemberSWR } from 'members/api';

const Wrapper = styled.div`
  padding: 16px 0px;
  border-radius: 6px;
  width: 100%;
`;

const labelSx = {
    fontFamily: 'Quicksand',
    color: '#16161D',
    fontSize: ['26px', '32px'],
    lineHeight: '130%',
    fontWeight: '400 !important',
    textAlign: 'center',
    letterSpacing: '-0.704px'
};

const FieldText = ({ name, type, disabled, placeholder, ...props }) => {
    return <Field
        component={TextField}
        name={name}
        type={type}
        showError
        placeholder={placeholder}
        m="auto"
        mb={[2, 4]}
        borderRadius='12px'
        sx={{
            width: 'inherit',
            borderColor: '#D9D9D9',
            cursor: disabled && 'not-allowed',
            borderRadius: '12px'
        }}
        {...props}
    />
}

const CustomerReviews = [
    {
        image: <Rating />,
        reviewer: '- Susan V,',
        reviewHeading: '“Very Happy.”',
        review: 'I so appreciate your bringing this into the world in time for my mother who has early stage Alzheimer’s. She is thrilled to do this — we record at least one new story whenever we visit her.',
    },
    {
        image: <Rating />,
        reviewer: '- Jesse O,',
        reviewHeading: '“Looking forward to the full launch!”',
        review:
            'My friends and I are wondering how we can help you grow. These memoirs are fantastic! So many things I didn’t know about Dad!',
    },
    {
        image: <Rating />,
        reviewer: '- Mike F,',
        reviewHeading: '“Extremely useful and comforting!”',
        review: "This has been an extremely useful and comforting tool for a large community of people who were devastated by the sudden and unexpected loss of a close friend. Through LifeTales we've excavated and shared stories that have connected and supported us across generations and geography.",
    },
];

function LoginScreen() {
    const { params: query } = useMatch();
    const { push } = useHistory();
    const { pathname } = useLocation();

    const [isLogin, setIsLogin] = useState(pathname === '/');
    const scrollToItemRef = useRef(null);

    const { entity: memoir } = useEntity({ id: query?.id, type: query?.type });

    const { revalidate } = useGetMemberSWR('me', {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    });

    const login = useLogin();
    const createNewAccount = useCreateNewV3Account()
    const sendToGTM = useSendToGTM();

    useEffect(() => {
        if (scrollToItemRef.current) {
            scrollToItemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [scrollToItemRef?.current]);

    const onSubmit = async (values, { setSubmitting, setErrors }) => {

        const newvalues = { ...values, ...query };
        if (query?.['private-code']) newvalues.promotion_code = query?.['private-code'];
        let redirectURL = pathname === '/' ? 'shared-with-me' : `/memoirs/${query.id}/milestones`;
        try {
            if (isLogin) {
                await login(values);
            }
            else {
                await createNewAccount(values);
            }
            revalidate()
            const gtmObject = isLogin
                ? {
                    event: 'memoirs.signin',
                    email: values.email,
                }
                : {
                    event: 'memoirs.registered',
                    firstName: values['first_name'],
                    lastName: values['last_name'],
                    email: values.email,
                };

            sendToGTM(gtmObject);
            push(redirectURL);
        } catch (err) {
            const errors = {};
            if (err && err.response && err.response.data && err.response.data.errors) {
                err.response.data.errors.forEach((error) => {
                    if (error.detail && error.detail.includes('Invalid Password')) {
                        errors.password = 'Invalid Password';
                    }
                    if (error.detail && error.detail.includes('Account Exists')) {
                        errors.email = 'An account with this email already exists';
                    }
                    if (error.detail && error.detail.includes('Account Not Found')) {
                        errors.email = 'Account Not Found';
                    }
                });
            }
        }
        setSubmitting(false);
    };

    const onBack = () => {
        if (memoir?.['subject-alive'] === false)
            push(`/memoirs/get-started/${query.id}/date-of-birth`)
        else
            push(`/memoirs/get-started/${query.id}/is-with-us`)
    }

    return (
        <FullPageFormWrap maxWidth={1200} innerWidth="100%" boxShadow='unset' bg="#f7f7f7" margin='auto' mt={[0, "50px"]} p={0} innerPadding={[10, 30]} >
            <Formik
                initialValues={
                    isLogin
                        ? {
                            email: '',
                            password: '',
                        }
                        : {
                            'first_name': '',
                            'last_name': '',
                            email: '',
                            password: '',
                        }
                }
                enableReinitialize
                validationSchema={
                    isLogin
                        ? Yup.object().shape({
                            email: Yup.string().email().required(),
                            password: Yup.string().min(6).required(),
                        })
                        : Yup.object().shape({
                            'first_name': Yup.string().required(),
                            'last_name': Yup.string().required(),
                            email: Yup.string().email().required(),
                            password: Yup.string().min(6).required(),
                        })
                }
                onSubmit={onSubmit}
            >
                {({ values, isSubmitting, handleSubmit }) => (
                    <Form>
                        <Flex
                            mt={[0, 0, 4]}
                            mb={['180px', '160px', '80px']}
                            justifyContent="space-between"
                            flexDirection={['column', 'column', 'row']}
                        >
                            <Box width={['100%', 500]} >
                                <Wrapper style={{ zIndex: 1 }}>
                                    {pathname !== '/' && <Text
                                        ref={scrollToItemRef}
                                        htmlFor="occasion-date"
                                        sx={labelSx}
                                        mt={[0, '30px']}
                                        mb={[1, 2]}
                                    >
                                        One last thing …
                                    </Text>}
                                    <Text
                                        htmlFor="occasion-date"
                                        sx={labelSx}
                                        mb={[1, 2]}
                                    >
                                        Please {isLogin ? 'Login to your account' : 'Create Your Account.'}
                                    </Text>
                                    {!isLogin && (
                                        <Flex>
                                            <FieldText
                                                name="first_name"
                                                placeholder="First name"
                                                width="50%"
                                                mr={[1, 3]}
                                                mt={0}
                                            />
                                            <FieldText
                                                name="last_name"
                                                placeholder="Last name"
                                                width="50%"
                                                mt={0}
                                            />
                                        </Flex>
                                    )}
                                    <FieldText name="email" width="100%" placeholder="Enter your email address" />
                                    <FieldText
                                        name="password"
                                        type="password"
                                        placeholder="Enter your password"
                                        width="100%"
                                    />

                                    <Box mt={1}>
                                        {isLogin ? (
                                            <Text
                                                sx={{
                                                    fontSize: ['16px', '24px'],
                                                    lineHeight: '130%',
                                                    color: '#8E8E8E',
                                                }}
                                            >
                                                Create an account?{' '}
                                                <Text
                                                    as="span"
                                                    fontWeight={700}
                                                    sx={{
                                                        color: '#7B33FB',
                                                        textDecorationLine: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => setIsLogin(false)}
                                                >
                                                    Sign up
                                                </Text>
                                            </Text>
                                        ) : (
                                            <Text
                                                textAlign="left"
                                                sx={{
                                                    fontSize: ['16px', '24px'],
                                                    lineHeight: '130%',
                                                    color: '#8E8E8E',
                                                }}
                                            >
                                                Already have an account?{' '}
                                                <Text
                                                    as="span"
                                                    fontWeight={700}
                                                    sx={{
                                                        color: '#7B33FB',
                                                        textDecorationLine: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => setIsLogin(true)}
                                                >
                                                    Log in
                                                </Text>
                                            </Text>
                                        )}
                                    </Box>
                                </Wrapper>
                                <Footer
                                    maxWidth="100vw"
                                    percentComplete={100}
                                    onNextPress={handleSubmit}
                                    disabled={isSubmitting || !values.email || !values.password}
                                    onBack={pathname !== '/' && onBack}
                                    type="submit"
                                    nextButtonText={isLogin ? 'Sign in' : 'Create account & finish'}
                                    sx={{ left: 0 }}
                                />
                            </Box>
                            <Box ml={[0, 0, 7]} maxWidth={['100%', '50%']}>
                                {CustomerReviews.map(({ image, reviewHeading, reviewer, review }) => (
                                    <Box mb={4}>
                                        <Box minWidth="max-content" textAlign="left">
                                            {image}
                                        </Box>
                                        <Text
                                            color="black"
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                lineHeight: '150%',
                                                letterSpacing: '-0.396px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {reviewHeading}
                                        </Text>
                                        <Text
                                            color="black"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '150%',
                                                letterSpacing: '-0.352px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {review}
                                        </Text>
                                        <Flex>
                                            <Text
                                                color="rgba(0, 0, 0, 0.50)"
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    lineHeight: '150%',
                                                    letterSpacing: '-0.352px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {reviewer}
                                            </Text>
                                            <Text
                                                color="#13C283"
                                                sx={{
                                                    fontSize: '16px',
                                                    fontStyle: 'italic',
                                                    fontWeight: 400,
                                                    lineHeight: '150%',
                                                    letterSpacing: '-0.352px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                &nbsp;Beta Tester
                                            </Text>
                                        </Flex>
                                    </Box>
                                ))}
                            </Box>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </FullPageFormWrap>
    );
}

export default connect(null, { updateMember })(LoginScreen);
