import { useContext, useMemo } from 'react';
import { useUserStoryShareFromStory } from 'story-shares/hooks/index';

import { CurrentCollectionContext } from '../../collections/current-collection-context';
import { useEntity } from '../../entities/hooks';

export const useHasStoryPermission = ({ feature, id }) => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  const { isPresent: isCollectionPresent } = useEntity(currentCollection);
  const userStoryShare = useUserStoryShareFromStory(id) || {};

  const { story } = useEntity({ id, type: 'stories' });
  const { storyShare } = useEntity({ id: userStoryShare.id, type: 'story-shares' });

  const { 'contact-role': role, archived } = storyShare;
  console.log(feature, role)
  return useMemo(() => {
    switch (feature) {
      case 'VIEW_STORY_OPTIONS':
      case 'TAG_STORY':
      case 'EDIT_STORY':
        return ['owner', 'collaborator'].includes(role);
      case 'ARCHIVE_STORY':
        if (role === 'read_only') return false;
        return role !== 'owner' && !isCollectionPresent && !archived;
      case 'UNARCHIVE_STORY':
        if (role === 'read_only') return false;
        return role !== 'owner' && !isCollectionPresent && archived;
      case 'START_MEETING':
      case 'SHARE_STORY':
        return role === 'owner';
      case 'DELETE_STORY':
        return role === 'owner' || role === 'collaborator';
      case 'MANAGE_STORY_TAGS':
        if (role === 'read_only') return false;
        return role !== 'owner' && role !== 'viewer' && isCollectionPresent;
      case 'VIEW_MEMBER_AVATARS':
      case 'VIEW_ACTIVITY':
      case 'TOGGLE_NOTIFICATIONS':
        return role !== 'read_only';
      case 'SEND_UPDATE_NOTIFICATION':
        if (!story.muted) return false;
        return role === 'owner';
      default:
        return true;
    }
  }, [archived, feature, isCollectionPresent, role, story.muted]);
};

const StoryPermission = ({ feature, id, children }) => {
  const hasPermission = useHasStoryPermission({ feature, id });

  if (typeof children === 'function') return children(hasPermission);

  return hasPermission ? children : false;
};

export default StoryPermission;
